<template>
  <u-figures
    class="lk-wrapper__figures"
    :items="[
      {'name':'f-lk-your-initiatives-1','image':'/frontend/assets/img/bg-shapes/lines.svg','animation':'fade-in-left'},
    ]"
    :full-width="true"
    :is-oveflow="true"
    position="static"
  >
    <div class="lk-your-initiatives">
      <h2 class="lk-your-initiatives__title tt-uppercase">
        Ваши инициативы
      </h2>
      <div class="lk-your-initiatives__table">
        <div class="lk-your-initiatives__table-head g-hidden-mobile">
          <div class="lk-your-initiatives__row">
            <div class="lk-your-initiatives__col">
              Название
            </div>
          </div>
        </div>
        <div class="lk-your-initiatives__table-body">
          <div
            v-for="(item) in items"
            :key="`lk-your-initiatives__row-${item.id}`"
            class="lk-your-initiatives__row"
          >
            <div class="lk-your-initiatives__col lk-your-initiatives__col-title" v-html="item.name"/>
            <div class="lk-your-initiatives__col">
              <a
                :href="hrefWithRegion(item.editLink)"
                class="lk-your-initiatives__link"
                target="_blank"
              >
                Редактировать инициативу
              </a>
              <a
                class="lk-your-initiatives__link"
                :href="hrefWithRegion(item.reportLink)"
                target="_blank"
              >
                Заполнить форму отчета
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </u-figures>
</template>

<script>
export default {
  name: 'LkYourInitiatives',
  props: {
    items: {
      type: Array,
      default: () => [
        {
          name: 'name',
          editLink: '/',
          reportLink: '/'
        }
      ]
    }
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.lk-your-initiatives';

#{$b} {
  font-family: $font-family-inter;
  color: $black-true;
  position: relative;

  // .lk-your-initiatives__title
  &__title {
    font-family: $font-family-fugue;
    font-weight: 500;
    font-size: 44px;
    line-height: 100%;
    margin: 0 0 32px 0;

    @include mobile {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  // .lk-your-initiatives__row
  &__row {
    display: grid;
    grid-template-columns: auto 425px;
    gap: 30px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @include mobile {
      display: block;
      padding: 34px 24px 24px 24px;
      background-color: $white-true;
      border-radius: 16px;
    }
  }

  // .lk-your-initiatives__table-head
  &__table-head {
    color: $color-info-secondary;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba($black-true, 0.2);
  }

  // .lk-your-initiatives__col
  &__col {
    display: flex;
    justify-content: space-between;

    @include mobile {
      display: block;
      text-align: center;
    }

    &-title {
      @include mobile {
        text-align: left;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba($black-true, 0.25)
      }
    }
  }

  // .lk-your-initiatives__link
  &__link {
    color: $color-base-origin;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @include mobile {
      display: block;
      text-align: center;
    }
  }
}
</style>
