<template>
  <div class="u-events">
    <div class="u-events-page__bg-circle-1" />
    <UFigures
      :items="[
        {'name':'f-events-page-mob-1','image':'/frontend/assets/img/bg-shapes/circle-group-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-events-page-mob-2','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-events-page-mob-3','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-events-page-mob-4','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
        {'name':'f-events-page-mob-5','image':'/frontend/assets/img/bg-shapes/circle-left-opacity.svg','animation':'fade-in-left'},
      ]"
      :fullWidth="true"
      position="static"
    >
      <div class="u-events__wrapper">
        <UEventsFilter
          class="u-events__filter"
          :initial-filters="initParams.initialFilters"
          :event-type-options="eventTypeOptions"
          :partners-options="partnersOptions"
          :region-options="regionsOptions"
          :year-options="yearOptions"
          :initiatives-options="initiativesOptions"
          :audience-options="audienceOptions"
          :initial-selected-options="initialSelectedOptions"
          @change="onFilterChange"
        />
        <div class="u-events__body">
          <UEventsList
            v-if="items.length"
            :items="items"
            ref="eventsList"
            isLinks
          />
          <NotFound v-else text="По данным параметрам ничего не найдено" />
          <div class="u-events__load-more" v-show="paginator.NavPageNomer < paginator.NavPageCount">
            <CustomButton
              theme="primary"
              size="md"
              @click="showNewCards(false)"
            >
              Показать ещё
            </CustomButton>
          </div>
        </div>
      </div>
    </UFigures>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
import { regionFromOptions } from '@/components/helpers/regionHelper.js'

export default {
  name: 'Events',
  props: {
    url: {
      type: String,
      required: true
    },
    initParams: {
      type: Object,
    },
    initItems: {
      type: Array,
      default: () => []
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      filterState: {},
      items: this.initItems,
      nextUrl: this.initNextUrl,
      paginator: this.initPaginator,
    };
  },
  computed: {
    ...mapGetters('region', ['GET_REGION']),
    initialSelectedOptions() {
      const result = {}
      const region = regionFromOptions(this.GET_REGION, this.initParams?.regions)?.code || ''

      if (region) {
        result.region = region
      }

      return result
    },
    partnersOptions() {
      return this.sortingAlphabetically(this.initParams.partners);
    },
    regionsOptions() {
      return this.sortingAlphabetically(this.initParams.regions);
    },
    eventTypeOptions() {
      return this.sortingAlphabetically(this.initParams.eventTypeOptions);
    },
    yearOptions() {
      return this.sortingAlphabetically(this.initParams.years);
    },
    initiativesOptions() {
      return this.sortingAlphabetically(this.initParams.initiativesOptions)
    },
    audienceOptions() {
      return this.sortingAlphabetically(this.initParams.audienceOptions)
    }
  },
  methods: {
    sortingAlphabetically(items) {
      items.sort((a, b) => {
        let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }

        return 0
      });

      items.sort((item) => {
        if (item.id === "") {
          return -1;
        }
      });

      return items;
    },
    formatDate(date) {
      return moment(date).locale("ru").format("DD MMMM / HH:mm")
    },
    onFilterChange(filterState) {
      this.filterState = filterState;
      this.loadFirstPage(true);
    },
    loadFirstPage() {
      const lines = [];
      if (this.filterState.filter) {
        lines.push(`filter=${this.filterState.filter}`);
      }
      if (this.filterState.region) {
        lines.push(`region=${this.filterState.region}`);
      }
      if (this.filterState.organizer) {
        lines.push(`partners=${this.filterState.organizer}`);
      }
      if (this.filterState.type) {
        lines.push(`type=${this.filterState.type}`);
      }
      if (this.filterState.year) {
        lines.push(`year=${this.filterState.year}`);
      }
      if (this.filterState.initiative) {
        lines.push(`initiative=${this.filterState.initiative}`);
      }
      if (this.filterState.audience) {
        lines.push(`audience=${this.filterState.audience}`);
      }
      if (this.filterState.month.length) {
        lines.push(this.filterState.month.map(id => `months[]=${id}`).join('&'));
      }
      const params = lines.join("&");

      this.nextUrl = `?AJAX=Y&PAGEN_${this.paginator.NavNum}=1&${params}`;
      this.loadMoreItems(true);
    },
    async loadMoreItems(clearItems) {
      const res = await axios.get(this.nextUrl);
      this.nextUrl = res.data.NEXT_URL;
      this.paginator = res.data.PAGINATOR;
      if (clearItems) {
        this.items = [];
      }
      res.data.ITEMS.map(item => {
        this.items.push(item);
      });
    },
    async showNewCards(clearItems){
      const bottom = this.$refs.eventsList.$el.getBoundingClientRect().bottom

      await this.loadMoreItems(clearItems)

      window.scrollTo({
        top:  window.scrollY + bottom,
        behavior: 'smooth'
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-events';
$offsetTop: 47px;

#{$b} {
  display: block;
  padding-bottom: 128px;

  @include tablet {
    padding-bottom: 64px;
  }

  &-section {
    position: relative;
    z-index: 4;
    padding-top: 0;
    padding-bottom: 129px;

    @include mobile {
      padding-bottom: 64px;
    }

  }

  // .u-events__wrapper
  &__wrapper {
    position: relative;
    z-index: 10;
  }

  &__filter {
    transform: translateY(-$offsetTop);
    margin-bottom: -$offsetTop;
  }

  &__body {
    margin-top: 40px;

    @include mobile {
      margin-top: 24px;
    }
  }

  &__load-more {
    margin-top: 32px;

    @include tablet {
      text-align: center;
    }
  }
}
</style>
